import clsx from "clsx";

export type RoundedButtonVariant = "small" | "normal";

const SmallButtonStyles = clsx`
    px-5 py-3
    xl:px-[1.125rem] xl:py-4
`;

const NormalButtonStyles = clsx`
    aspect-[3/1] px-[2.125rem] py-3
    xl:px-[2.2813rem] xl:py-4
`;

export const RoundedButtonStyles = (variant: RoundedButtonVariant) => clsx`
    ${variant === "small" && SmallButtonStyles}
    ${variant === "normal" && NormalButtonStyles}
    bg-light-second text-dark-main body-xs-bold
    w-fit h-fit transition-all outline-none outline-0
    xl:text-buttons
    hover-default hover:bg-light-main
    focus-visible:bg-mint-400
`;
