import { object, string, InferType } from "yup";

export const contactFormSchema = object({
  name: string()
    .required("Field cannot be empty")
    .typeError("Enter a valid name"),
  mail: string()
    .email("Enter a valid email eg. name@example.com")
    .required("Field cannot be empty")
    .typeError("Enter a valid email eg. name@example.com"),
  message: string()
    .required("Field cannot be empty")
    .max(1024, "Max message characters count is 1024")
    .typeError("Enter a valid message"),
}).required("All field are required");

export type ContactFormInputs = InferType<typeof contactFormSchema>;
