import Grid from "@/components/Grid";
import clsx from "clsx";
import React from "react";
import ContactBox from "./ContactBox";
import ContactForm from "./ContactForm";

const ContactSection = () => {
  return (
    <section
      id="contact"
      className={clsx(
        "mt-10 scroll-mt-20 snap-center mb-10",
        "sm:mb-6",
        "md:mt-[4.5rem] md:mb-11",
        "lg:mb-16 lg:mt-[4.5rem]",
        "xl:mb-[5.5rem]",
        "2xl:mt-20 2xl:mb-[6.5rem]"
      )}
    >
      <Grid>
        <div
          className={clsx(
            "col-span-full flex flex-col gap-6",
            "sm:gap-8",
            "lg:col-span-6 "
          )}
        >
          <div className={clsx("flex flex-col gap-4")}>
            <h2
              className={clsx(
                "heading-4 font-bsc",
                "sm:heading-3 sm:text-center",
                "lg:text-left",
                "xl:heading-2",
                "2xl:heading-1"
              )}
            >
              Let us become your
              <br className="hidden md:inline-block lg:hidden" /> trusted
              partner
            </h2>
            <p
              className={clsx(
                "text-light-second body-s",
                "sm:text-center",
                "lg:text-left",
                "xl:body-m"
              )}
            >
              Contact us and learn how we can tailor our
              <br className="hidden md:inline-block lg:hidden" /> services to
              meet your medical needs.
            </p>
          </div>
          <div className="flex-1 max-h-40 h-full" />
          <div className="w-full md:hidden lg:block">
            <ContactBox />
          </div>
        </div>

        <div className="hidden md:block lg:hidden col-start-2 col-end-8 md:mt-8">
          <ContactBox />
        </div>

        <ContactForm />
      </Grid>
    </section>
  );
};

export default ContactSection;
