import clsx from "clsx";
import React, { FunctionComponent } from "react";

const Lines: FunctionComponent = () => {
  return (
    <>
      <div
        className={clsx(
          "w-px bg-lines-on-dark fixed top-0 bottom-0 left-4",
          "sm:left-8",
          "lg:left-[3.125rem]",
          "xl:left-[calc((50%-0.5px-540px)/2)]",
          "2xl:left-[calc((50%-0.5px-588px)/2)]"
        )}
      />
      <div
        className={clsx(
          "w-px bg-lines-on-dark fixed top-0 bottom-0 right-4",
          "sm:right-8",
          "lg:right-[3.125rem]",
          "xl:right-[calc((50%-0.5px-540px)/2)]",
          "2xl:right-[calc((50%-0.5px-588px)/2)]"
        )}
      />
    </>
  );
};

export default Lines;
