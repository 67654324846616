import React from "react";
import FormError from "@/components/Form/FormError";
import Input from "@/components/Form/Input";
import Textarea from "@/components/Form/Textarea";
import RoundedButton from "@/components/RoundedButton";
import { motion } from "framer-motion";
import clsx from "clsx";
import { useFormContext } from "react-hook-form";
import { ContactFormInputs } from "./form";

const FormContent = () => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<ContactFormInputs>();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      key="form"
      className={clsx("gap-8 flex flex-col")}
    >
      <Input
        id="name"
        placeholder="Name"
        error={errors.name?.message}
        {...register("name")}
      />
      <Input
        id="mail"
        placeholder="Email"
        {...register("mail")}
        error={errors.mail?.message}
        type="email"
      />
      <Textarea
        id="message"
        className="max-h-64 min-h-[4rem] h-20"
        placeholder="Message"
        {...register("message", { required: true })}
        error={errors.message?.message}
      />

      {errors.root?.message && (
        <FormError
          className="whitespace-pre-wrap"
          message={errors.root?.message}
        />
      )}

      <div className="flex-1" />

      <RoundedButton variant="small" isLoading={isSubmitting}>
        Send message
      </RoundedButton>
    </motion.div>
  );
};

export default FormContent;
