import RoundedButton from "@/components/RoundedButton";
import clsx from "clsx";
import { motion } from "framer-motion";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import ImageFormSended from "./image_form_sended.webp";

type FormSendedProps = {
  onReset: () => void;
  minHeight: number;
};

const FormSended: FunctionComponent<FormSendedProps> = ({
  minHeight,
  onReset,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={clsx(
        "flex flex-col items-center lg:items-start text-center lg:text-left"
      )}
      style={{ minHeight: minHeight }}
    >
      <Image
        src={ImageFormSended}
        alt=""
        className={clsx("h-[120px] w-[172px]")}
      />
      <h3 className={clsx("title-s-bold mt-6 mb-2", "xl:title-m-bold")}>
        Your message has been sent successfully!
      </h3>
      <span className={clsx("flex-1 text-light-second body-s xl:body-m")}>
        Someone from our support team will reply to your message within 2
        working days.
      </span>
      <RoundedButton variant="small" onClick={onReset}>
        New message
      </RoundedButton>
    </motion.div>
  );
};

export default FormSended;
