import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";

type LinesContainerProps = {} & HTMLAttributes<HTMLDivElement>;

const LinesContainer: FunctionComponent<LinesContainerProps> = ({
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        className,
        "px-4",
        "sm:px-8",
        "lg:px-[3.125rem]",
        "xl:mx-auto xl:px-[3.125rem] xl:max-w-[calc(100%-(50%-640px))]",
        "2xl:px-[5.625rem] 2xl:max-w-[calc(100%-(50%-768px))]"
      )}
      {...props}
    />
  );
};

export default LinesContainer;
