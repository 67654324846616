import clsx from "clsx";
import React, { InputHTMLAttributes, forwardRef } from "react";
import Placeholder from "./Placeholder";
import FormError from "./FormError";

type InputProps = { error?: string } & InputHTMLAttributes<HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, placeholder, error, ...props }, ref) => {
    return (
      <div className={clsx("flex flex-col")}>
        <Placeholder htmlFor={props.id} placeholder={placeholder} />
        <div className="relative">
          <input
            ref={ref}
            className={clsx(
              className,
              "autofill:shadow-dark-second autofill:shadow-autofill autofill:bg-clip-content",
              "lg:autofill:shadow-dark-main",
              "body-s peer block  text-fill-light-main",
              "focus:body-s-bold",
              "w-full bg-transparent",
              "focus:outline-none"
            )}
            {...props}
          />
          <div className={clsx("bg-lines-on-dark h-px w-full mt-1.5")} />
          <div
            className={clsx(
              "absolute left-0 h-px bottom-0 right-0 transition-transform bg-red-400",
              error ? "scale-x-100" : "scale-x-0"
            )}
          />
          <div
            className={clsx(
              "absolute left-0 h-px bottom-0 scale-x-0 right-0 transition-transform ",
              "bg-gradient-to-r",
              "from-mint-500 via-mint-500 to-red-500",
              "peer-focus:scale-x-100 peer-hover:scale-x-100",
              "from-25% via-40% to-60%"
            )}
          />
        </div>
        {error && <FormError message={error} />}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
