import { Barlow, Barlow_Semi_Condensed } from "next/font/google";
import React from "react";

const barlow = Barlow({
  weight: ["300", "500", "600"],
  subsets: ["latin"],
  display: "block",
  variable: "--lc-font",
});

const barlowSemiCondensed = Barlow_Semi_Condensed({
  weight: ["300", "400", "500", "600"],
  subsets: ["latin"],
  display: "block",
  variable: "--barlow-semi-condensed",
});

const FontStyle = () => {
  return (
    <style jsx global>{`
      :root {
        --lc-font: ${barlow.style.fontFamily};
        --barlow: ${barlow.style.fontFamily};
        --barlow-semi-condensed: ${barlowSemiCondensed.style.fontFamily};
      }
    `}</style>
  );
};

export default FontStyle;
