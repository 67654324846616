import React, { useState, FunctionComponent, useEffect } from "react";
import LinesContainer from "./LinesContainer";
import clsx from "clsx";

type SkipToContentProps = {
  href: string;
};

const SkipToContent: FunctionComponent<SkipToContentProps> = ({ href }) => {
  const [skipToContent, setSkipToContent] = useState(true);

  useEffect(() => {
    const element = document.getElementById(href);
    const callback = () => setSkipToContent(false);
    element?.addEventListener("click", callback);
    return () => element?.removeEventListener("click", callback);
  }, [href]);

  return skipToContent ? (
    <div className="top-20 fixed z-50 w-full left-0 right-0 h-0">
      <LinesContainer className="w-full mx-auto absolute left-0 right-0 top-0 bottom-0">
        <a
          href={href}
          className={clsx(
            "text-light-main border-mint-500 bg-dark-second",
            "scale-0 w-fit h-fit block opacity-0 border outline-none rounded-[0.25rem] body-xs transition-opacity py-1 px-3",
            "focus:opacity-100 focus:scale-100"
          )}
          onBlur={() => setSkipToContent(false)}
          onClick={() => setSkipToContent(false)}
        >
          skip to content
        </a>
      </LinesContainer>
    </div>
  ) : null;
};

export default SkipToContent;
