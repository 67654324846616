import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";

type GridProps = {} & HTMLAttributes<HTMLDivElement>;

const Grid: FunctionComponent<GridProps> = ({ className, ...props }) => {
  return (
    <div
      className={clsx(
        className,
        "grid grid-cols-4 w-full gap-x-3 px-8",
        "sm:grid-cols-6 sm:px-16 sm:gap-x-4",
        "md:grid-cols-8 md:px-20",
        "lg:grid-cols-12 lg:px-[6.25rem]",
        "xl:mx-auto xl:px-[6.25rem] xl:max-w-screen-xl xl:gap-x-6",
        "2xl:px-[11.25rem] 2xl:max-w-screen-2xl"
      )}
      {...props}
    />
  );
};

export default Grid;
