import Head from "next/head";
import React, { FunctionComponent } from "react";

const title = "Dedicated medical couriers & services | Medical Logistics";
const description =
  "No mistakes, No delays, No more lost packages. Medical couriers and services in London and across the UK.";
const bannerUrl = "https://medicallogistics.co.uk/medical-couriers-london.png";

type GeneralHeadProps = {
  children?: React.ReactNode;
};

const GeneralHead: FunctionComponent<GeneralHeadProps> = ({ children }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://medicallogistics.co.uk/" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={bannerUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://medicallogistics.co.uk/" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={bannerUrl}></meta>

      <meta name="theme-color" content="#161718" />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      <link rel="apple-touch-icon" href="/favicon.png" />
      <link rel="icon" href="/favicon.png" />

      {children}
    </Head>
  );
};

export default GeneralHead;
