import { Config } from "./Config";

const sendContactFormEmailUrl = Config.SEND_MAIL_URL;

export const sendMail = async (
  name: string,
  message: string,
  email: string
) => {
  if (!Config.IS_PROD) return;
  const data = {
    subject: "Medical Logistics landing inquire",
    name: name,
    email: email,
    phone: "-",
    message: message,
  };
  await fetch(sendContactFormEmailUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "no-cors",
    body: JSON.stringify({
      data,
    }),
  });
};
