import clsx from "clsx";
import React, { SVGProps } from "react";

const IconStyle = clsx`
    w-6 h-6
`;
const TransitionStyle = clsx`
    transition-colors
    group-hover:fill-mint-600
    group-focus:fill-mint-600
`;

export const PhoneIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    className={clsx(IconStyle, className)}
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#F6F9F9" className={TransitionStyle} />
    <path
      fill="#161718"
      d="M18.183 19a8.717 8.717 0 0 0 .817-.039v-4.55l-3.889-.778-2.255 2.256a14.507 14.507 0 0 1-2.713-2.12 12.711 12.711 0 0 1-2.051-2.625l2.216-2.236L9.588 5h-4.55c-.025.13-.038.266-.038.408v.409c0 1.672.373 3.302 1.118 4.89a14.943 14.943 0 0 0 2.955 4.23 14.584 14.584 0 0 0 4.22 2.955C14.88 18.63 16.51 19 18.183 19Z"
    />
  </svg>
);

export const MailIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    className={clsx(IconStyle, className)}
    {...props}
  >
    <circle cx={12} cy={12} r={12} fill="#F6F9F9" className={TransitionStyle} />
    <path
      fill="#161718"
      d="m17 9.5-5 3.125L7 9.5V8.25l5 3.125 5-3.125M17 7H7c-.694 0-1.25.556-1.25 1.25v7.5A1.25 1.25 0 0 0 7 17h10a1.25 1.25 0 0 0 1.25-1.25v-7.5A1.25 1.25 0 0 0 17 7Z"
    />
  </svg>
);

export const LinkedInIcon = ({
  className,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5rem"
    height="1.5rem"
    fill="none"
    className={clsx(IconStyle, className)}
    {...props}
  >
    <path
      fill="#F6F9F9"
      className={TransitionStyle}
      fillRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm6.15-2.025V18H8.7V9.975H6.15ZM6 7.425c0 .825.6 1.425 1.425 1.425.825 0 1.425-.6 1.425-1.425C8.85 6.6 8.25 6 7.425 6 6.675 6 6 6.6 6 7.425ZM15.45 18h2.4v-4.95c0-2.475-1.5-3.3-2.925-3.3-1.275 0-2.175.825-2.4 1.35V9.975h-2.4V18h2.55v-4.275c0-1.125.75-1.725 1.5-1.725s1.275.375 1.275 1.65V18Z"
      clipRule="evenodd"
    />
  </svg>
);
