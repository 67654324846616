import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { sendMail } from "@/utils/sendMail";
import FormSended from "./FormSended";
import { AnimatePresence, motion } from "framer-motion";
import FormContent from "./FormContent";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactFormInputs, contactFormSchema } from "./form";

const ContactForm = () => {
  const formContentRef = useRef<HTMLFormElement>(null);
  const [minFormHeight, setMinFormHeight] = useState(0);

  const methods = useForm<ContactFormInputs>({
    resolver: yupResolver(contactFormSchema),
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = useCallback(
    async (values: ContactFormInputs) => {
      try {
        await sendMail(values.name, values.message, values.mail);
      } catch (error) {
        setError("root", {
          message:
            "There was an error while submitting the form. Please try again later.",
        });
      }
    },
    [setError]
  );

  useEffect(() => {
    const height = formContentRef.current?.offsetHeight ?? 0;
    setMinFormHeight(height);
  }, []);

  return (
    <div
      className={clsx(
        "mt-6",
        "col-span-full bg-dark-second rounded-lg border border-lines-on-dark",
        "md:mt-8 md:col-start-2 md:col-end-8",
        "lg:col-span-6 lg:mt-0 lg:bg-dark-main",
        "xl:col-start-8 xl:col-end-13"
      )}
    >
      <div className={clsx("py-8 px-5 h-full", "sm:px-8 sm:py-10", "lg:p-10")}>
        <AnimatePresence initial={false} mode="wait">
          {isSubmitSuccessful && (
            <FormSended
              minHeight={minFormHeight}
              key="form-sended"
              onReset={() => reset()}
            />
          )}
          {!isSubmitSuccessful && (
            <FormProvider {...methods} key="form-content">
              <form ref={formContentRef} onSubmit={handleSubmit(onSubmit)}>
                <FormContent />
              </form>
            </FormProvider>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ContactForm;
