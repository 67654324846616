import { motion } from "framer-motion";
import React, { FunctionComponent, HTMLAttributes } from "react";

type MainProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  "onAnimationStart" | "onDragStart" | "onDragEnd" | "onDrag"
>;

const Main: FunctionComponent<MainProps> = ({ ...props }) => {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, bounce: false, ease: "linear" }}
      {...props}
    />
  );
};

export default Main;
