import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const COOKIES_ACCEPTED_KEY = "COOKIES_ACCEPTED";

const useCookiesAccepted = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (COOKIES_ACCEPTED_KEY == cookiePair[0].trim()) setAccepted(true);
    }
  }, []);

  return [accepted, setAccepted];
};

export default useCookiesAccepted;
