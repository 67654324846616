import {
  RoundedButtonStyles,
  RoundedButtonVariant,
} from "@/styles/RoundedButtonStyles";
import clsx from "clsx";
import React, { ButtonHTMLAttributes, FunctionComponent, useId } from "react";

type RoundedButtonProps = {
  variant?: RoundedButtonVariant;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const RoundedButton: FunctionComponent<RoundedButtonProps> = ({
  style,
  variant = "normal",
  isLoading,
  className,
  disabled,
  children,
  ...props
}) => {
  const id = useId();
  return (
    <div>
      <button
        className={clsx(className, "relative", RoundedButtonStyles(variant))}
        style={{ ...style, clipPath: `url(#${id})` }}
        disabled={isLoading ? true : disabled}
        {...props}
      >
        {isLoading && (
          <>
            <div
              className={clsx(
                "not-sr-only inline-block h-7  m-auto w-7 animate-spin rounded-full border-2 border-solid border-current border-r-transparent",
                "absolute left-0 right-0 top-0 bottom-0"
              )}
              role="status"
            />
            <span className="sr-only left-0 right-0 bottom-1 top-0 absolute my-auto h-fit">
              Loading...
            </span>
          </>
        )}
        <span className={isLoading ? "opacity-0" : "opacity-100"}>
          {children}
        </span>
      </button>
      <svg width="0" height="0">
        <clipPath id={id} clipPathUnits="objectBoundingBox">
          <path d="M0.036,0.212 C0.045,0.086,0.083,-0.003,0.125,0 L0.866,0.05 C0.899,0.053,0.93,0.113,0.944,0.205 L0.99,0.571 C1,0.749,0.988,0.919,0.923,0.925 L0.093,1 C0.034,1,-0.011,0.842,0.002,0.667 L0.036,0.212" />
        </clipPath>
      </svg>
    </div>
  );
};

export default RoundedButton;
