import clsx from "clsx";
import React, { FunctionComponent, LabelHTMLAttributes } from "react";

type PlaceholderProps = {
  placeholder?: string;
} & LabelHTMLAttributes<HTMLLabelElement>;

const Placeholder: FunctionComponent<PlaceholderProps> = ({
  placeholder,
  className,
  ...props
}) => {
  return (
    <label
      className={clsx(
        className,
        "body-small-bold uppercase mb-1 text-light-second",
        "xl:lead-xs xl:mb-2"
      )}
      {...props}
    >
      {placeholder}
    </label>
  );
};

export default Placeholder;
