import Image from "next/image";
import React, { FunctionComponent, HTMLAttributes } from "react";
import IconError from "./icon-error.svg";
import clsx from "clsx";

type FormErrorProps = { message: string } & Omit<
  HTMLAttributes<HTMLSpanElement>,
  "children"
>;

const FormError: FunctionComponent<FormErrorProps> = ({
  message,
  className,
  ...props
}) => {
  return (
    <span
      className={clsx(
        className,
        "body-small text-red-400 self-end flex gap-1 items-start mt-1 text-right"
      )}
      {...props}
    >
      {message}
      <Image src={IconError} alt="" width={16} height={16} className="mt-0.5" />
    </span>
  );
};

export default FormError;
