import { NavigationUrls } from "@purpledeerstudio/logistics-components/dist/types/NavigationUrls";
import { Config } from "./Config";

const DEV_URLS = {
  COURIERS: "https://couriers-landing-staging--dev-6eh5c1tu.web.app",
  MEDICAL_SERVICES: "https://med-landing-staging--dev-8yaoq791.web.app",
};

const STAGING_URLS = {
  COURIERS: "https://couriers-landing-staging.web.app",
  MEDICAL_SERVICES: "https://med-landing-staging.web.app",
};

const PROD_URLS = {
  COURIERS: "https://bookcourier.medicallogistics.co.uk",
  MEDICAL_SERVICES: "https://medicalservices.medicallogistics.co.uk",
};

const CURRENT_URLS = Config.IS_DEV
  ? DEV_URLS
  : Config.IS_STAGING
  ? STAGING_URLS
  : PROD_URLS;

export const Urls = {
  CQC: "https://www.cqc.org.uk/provider/1-10972391656/registration-info",
  UKAS: "https://www.ukas.com/wp-content/uploads/schedule_uploads/971748/22387-Medical-Multiple.pdf",
  IN_CLINIC: "https://medicallaboratories.co.uk",
  TRUSTPILOT: "https://uk.trustpilot.com/review/medicallogistics.co.uk",
  ISO: "/iso_certificate.pdf",
  ...CURRENT_URLS,
};

export const FooterUrls: NavigationUrls = {
  modernSlavery: "/modern-slavery-policy",
  privacy: "/privacy-and-cookies-policy",
  terms: "/terms-and-conditions",
  iso: "/iso_certificate.pdf",
  contactUs: "#contact",
  home: "/",
  medicalCouriers: CURRENT_URLS.COURIERS,
  medicalServices: CURRENT_URLS.MEDICAL_SERVICES,
  aboutUs: "/about-us",
  pressAboutUs: "/press-about-us",
};
