import useCookiesAccepted, {
  COOKIES_ACCEPTED_KEY,
} from "@/utils/useCookiesAccepted";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import CookiesIcon from "./icon_cookies.svg";
import Image from "next/image";

const CookiesBox = () => {
  const [cookiesAccepted, setAccepted] = useCookiesAccepted();
  const acceptCookiePolicy = () => {
    document.cookie = `${COOKIES_ACCEPTED_KEY}=true; domain=medicallogistics.co.uk; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    setAccepted(true);
  };

  return (
    <AnimatePresence>
      {!cookiesAccepted && (
        <motion.div
          initial={{ y: "150%" }}
          animate={{
            y: "0%",
            transition: { bounce: false, duration: 1, delay: 2 },
          }}
          exit={{ y: "150%", transition: { duration: 1, bounce: false } }}
          className={clsx(
            "fixed z-50 flex p-4 box-border bg-[rgb(35_40_41)] text-light-main",
            "justify-between right-0 bottom-0 w-full items-center",
            "md:bottom-6 md:right-6 md:justify-start md:w-fit md:rounded-2xl md:rounded-bl-none"
          )}
        >
          <Image src={CookiesIcon} alt="" />
          <span className="md:max-w-[13.125rem] text-sm leading-[140%] font-normal font-bsc ml-3 text-[rgb(240_245_245)]">
            <span className="hidden md:inline-block">We use cookies.</span> By
            using our website you agree to use of our cookies.{" "}
            <Link
              href="/privacy-and-cookies-policy"
              className={clsx(
                "underline text-mint-500",
                "hover:no-underline",
                "focus:no-underline focus:outline-1 focus:outline focus:outline-mint-500"
              )}
            >
              Read cookies policies.
            </Link>
          </span>
          <button
            className="py-1 px-[0.625rem] ml-3 rounded-lg bg-mint-500 body-small text-[#242729] font-bsc !font-medium focus-default hover-default"
            onClick={acceptCookiePolicy}
          >
            Accept
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CookiesBox;
