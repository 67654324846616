import clsx from "clsx";
import Link from "next/link";
import React from "react";
import { MailIcon, PhoneIcon } from "@/components/SocialIcons";
import { Config } from "@/utils/Config";

const ContactBox = () => (
  <div
    className={clsx(
      "flex flex-col gap-4 p-6 bg-dark-second rounded-lg border border-lines-on-dark body-s break-all",
      "sm:px-8 sm:gap-5 sm:body-m",
      "lg:bg-dark-main"
    )}
  >
    <Link
      href={`tel:${Config.PHONE.replace(/ /g, "")}`}
      className="w-fit flex gap-2 focus-default hover-default group items-center"
    >
      <PhoneIcon />
      <span className="flex-1">{Config.PHONE}</span>
    </Link>
    <Link
      href={`mailto:${Config.MAIL}`}
      className="w-fit flex gap-2 focus-default hover-default group text-mint-400 underline items-center"
    >
      <MailIcon />
      <span className="flex-1">{Config.MAIL}</span>
    </Link>
  </div>
);

export default ContactBox;
